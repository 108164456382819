@import '@popsure/dirty-swan/dist/grid';

.p--serif {
  font-family: 'Merriweather', Georgia, serif;
}

.page-padding {
  max-width: 1060px;
  margin: 0 auto;
}

@media screen and (max-width: 1140px) {
  .page-padding {
    margin-right: 40px;
    margin-left: 40px;
  }
}

@media screen and (max-width: 720px) {
  .page-padding {
    margin-right: 16px;
    margin-left: 16px;
  }
}

@media screen and (max-width: 540px) {
  .page-padding {
    margin-right: 8px;
    margin-left: 8px;
  }
}

// TODO: [KONG] this is the new class for components that will have button tag for interactive purposes
// Initializes button css. This is a quick implementation. Should be moved to DS eventually.
.ds-interactive-component {
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;
  text-align: inherit;
}

// We override the module-level style of the TextWithImage CMS component based on how it's composed with other sections in the same page.
// This applies to both Strapi and Prismic components.
.header-container + .text-img-container,
div:has(.header-container) + div > .text-img-container,
.text-img-container + .text-img-container,
div:has(.text-img-container) + div > .text-img-container,
.text-img-container + .text-modal-container + .text-img-container,
div:has(.text-img-container)
  + div:has(.text-modal-container)
  + div
  > .text-img-container {
  margin-top: 40px;

  @include p-size-tablet {
    margin-top: 48px;
  }
}

// Add some padding for frozen footers
#__next > div:last-child {
  padding-bottom: 112px;
}
