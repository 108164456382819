@import '@popsure/dirty-swan/dist/colors';

.container {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;

  background-color: white;

  z-index: 1000;

  transition: box-shadow 0.3s;

  height: 64px;

  &Shadow {
    box-shadow: 0px 4px 36px rgba(196, 196, 196, 0.24);
  }
}

.separator {
  border-top: 1px solid $ds-grey-300;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.logo {
  position: absolute;
  content: '';
  background: url('/static/components/NavigationBar/logo-mobile.svg');
  background-repeat: no-repeat;

  top: 16px;
  left: 16px;

  width: 32px;
  height: 32px;
}

.button {
  position: absolute;
  cursor: pointer;
}

.signinButton {
  top: 12px;
  right: 56px;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 40px;
  height: 40px;

  &:focus-visible {
    outline: 2px solid $ds-grey-900;
    border-radius: 8px;
  }
}

.signinIcon {
  margin: 0;
}

.hamburgerButton {
  background: url('/static/components/NavigationBar/burger-menu.svg');
  background-size: 24px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  top: 12px;
  right: 8px;
  width: 40px;
  height: 40px;

  &:focus-visible {
    outline: 2px solid $ds-grey-900;
    border-radius: 8px;
  }
}

.closeButton {
  position: relative;
  bottom: 8px;
  left: 12px;
}

.closeButtonIcon {
  color: $ds-primary-500 !important;
  min-width: 26px !important;
  width: 26px !important;
  height: 26px !important;
}

.categoryTitle {
  margin-left: 12px;
  height: 24px;
  line-height: 24px;

  color: $ds-grey-500;

  letter-spacing: 0.5px;
}

.languageSwitcherWrapper {
  position: absolute;
  top: 0;
  left: 0;
}
  
.product {
  cursor: pointer;

  display: block;

  text-decoration: none;
  line-height: 24px;

  background-color: transparent;
  border: none;

  color: $ds-grey-900;

  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  padding: 12px 0 12px 16px;

  &:focus-visible {
    outline: 2px solid $ds-grey-900;
  }
}

.table {
  width: 100%;

  .cell {
    cursor: pointer;

    display: block;
    padding: 0 16px;

    height: 56px;
    line-height: 56px;

    font-size: 18px;
    letter-spacing: 0.5px;
    text-decoration: none;

    color: $ds-grey-900;

    &:focus-visible {
      outline: 2px solid $ds-grey-900;
      outline-offset: -2px;
    }

    &Product {
      position: relative;
      margin: 0;
      padding: 0 16px;
      width: 100%;

      &::after {
        content: '';
        position: absolute;

        background: url('/static/components/NavigationBar/chevron.svg');
        background-repeat: no-repeat;

        right: 16px;

        top: 50%;
        transform: translateY(-50%);

        width: 16px;
        height: 12px;

        background-size: contain;
      }

      &Open::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.dropdownChildren {
  & > button + div {
    margin-top: 24px;
  }
}

.products {
  padding-top: 4px;
}
